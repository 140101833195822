.navbar-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: fit-content;
  justify-content: space-between;
  background-color:  #424242;

}
list-container{
  margin:0;
  padding:0;
  list-style: none;
  top:0;
  right:0;

}

