@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  height: 100vh;
  
}

.main-container{
  background-color: #121212;
}
.content-container{
  color:#FFFF;
  font-family: 'Lato', sans-serif;
}


.navbar-container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: space-between;
  background-color:  #424242;

}
list-container{
  margin:0;
  padding:0;
  list-style: none;
  top:0;
  right:0;

}


.main-container{
  height: 100vh;
  
}
.myself{
  font-size: 120px;
}
.first{
    padding-top: 10%;
    padding-left: 10%;
}
.last{
  padding-left: 40%;
}

.paragraph{
  font-size: 25px;
  padding-right: 5%;
  padding-top: 10%;
}
.link-btn{
  font-size : 30px;
  font-style: italic;
}
#sign{
  font-size: 10px;
}
.button-holder{
  margin-top: 5%;
}
#text {
  color:#FFFF
}
.main-container{
  height: 100vh;
}
h1{
  
  padding-top: 5%;
  color:#64FFDA;
  letter-spacing: 0.1em;
  font-size: 60px;
  font-weight: bolder;
}
#intro{
  color: #FFFF;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
}
p{
  font-weight: bold;
  font-style: italic; 
}
.content-container {
  padding-top: 5%;
}
h3{
  color: rgb(71, 245, 204);
  font-family:'Lato', sans-serif;
  letter-spacing: 0.05em;
}
h4{
  color: rgb(71, 245, 204);
  font-family:'Lato', sans-serif;
  letter-spacing: 0.05em;
}
p{
  color: black;
  font-family:'Lato', sans-serif;
  letter-spacing: 0.05em;
}
h1{
  text-align: center;
}
#button
{
  padding:0;
  margin:0;
  float:center;
  text-align: center;
  padding-bottom: 2%;
}
