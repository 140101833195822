.main-container{
  height: 100vh;
}
h1{
  
  padding-top: 5%;
  color:#64FFDA;
  letter-spacing: 0.1em;
  font-size: 60px;
  font-weight: bolder;
}
#intro{
  color: #FFFF;
  font-size: 25px;
  font-style: normal;
  font-weight: normal;
}
p{
  font-weight: bold;
  font-style: italic; 
}
.content-container {
  padding-top: 5%;
}