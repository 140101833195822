.main-container{
  height: 100vh;
  
}
.myself{
  font-size: 120px;
}
.first{
    padding-top: 10%;
    padding-left: 10%;
}
.last{
  padding-left: 40%;
}

.paragraph{
  font-size: 25px;
  padding-right: 5%;
  padding-top: 10%;
}
.link-btn{
  font-size : 30px;
  font-style: italic;
}
#sign{
  font-size: 10px;
}
.button-holder{
  margin-top: 5%;
}
#text {
  color:#FFFF
}