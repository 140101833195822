@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
.App{
  height: 100vh;
  
}

.main-container{
  background-color: #121212;
}
.content-container{
  color:#FFFF;
  font-family: 'Lato', sans-serif;
}

